import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withState, withProps, withHandlers } from "recompose";
import store from "../../store";
import wrapProvider from "../../helpers/components/wrapProvider";
import preventDefault from "../../helpers/components/preventDefault";
import { fetchInvites, loadMoreInvites } from "../../actions/invites";
import {
  fetchParticipations,
  loadMoreParticipations,
} from "../../actions/participations";
import {
  invitesSelector,
  invitesLoadingSelector,
  participantsLoadingSelector,
  participationsSelector,
  loadMoreUrlParticipationsSelector,
  loadMoreUrlInvitesSelector,
} from "../../selectors/invites";

import UnreadBadge from "components/shared/UnreadBadge";
import InvitesList from "components/invites/InvitesList";
import { useFeaturesUnreads } from "../../api/unreads";

function Invites({
  setActiveTab,
  activeTab,
  load,
  items,
  loadMore,
  loadMoreUrl,
  isLoading,
}) {
  const { data: unreads } = useFeaturesUnreads();

  return (
    <div className="tixxt-tabs-layout">
      <div className="tabs-region">
        <ul className="nav nav-tabs">
          <li className={activeTab === "open" ? "active" : ""}>
            <a onClick={preventDefault(() => setActiveTab("open"))} href="#">
              <div className="flex">
                {I18n.t("js.calendars.appointments.invites.tabs.open")}
                {<UnreadBadge count={unreads?.Calendars} className="ml-1" />}
              </div>
            </a>
          </li>
          <li className={activeTab === "upcoming" ? "active" : ""}>
            <a
              onClick={preventDefault(() => setActiveTab("upcoming"))}
              href="#"
            >
              {I18n.t("js.calendars.appointments.invites.tabs.upcoming")}
            </a>
          </li>
          <li className={activeTab === "accepted" ? "active" : ""}>
            <a
              onClick={preventDefault(() => setActiveTab("accepted"))}
              href="#"
            >
              {I18n.t("js.calendars.appointments.invites.tabs.accepted")}
            </a>
          </li>
          <li className={activeTab === "declined" ? "active" : ""}>
            <a
              onClick={preventDefault(() => setActiveTab("declined"))}
              href="#"
            >
              {I18n.t("js.calendars.appointments.invites.tabs.declined")}
            </a>
          </li>
          <li className={activeTab === "expired" ? "active" : ""}>
            <a onClick={preventDefault(() => setActiveTab("expired"))} href="#">
              {I18n.t("js.calendars.appointments.invites.tabs.expired")}
            </a>
          </li>
          <li className={activeTab === "obsolete" ? "active" : ""}>
            <a
              onClick={preventDefault(() => setActiveTab("obsolete"))}
              href="#"
            >
              {I18n.t("js.calendars.appointments.invites.tabs.obsolete")}
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-content-region">
        <InvitesList
          isLoading={isLoading}
          inviteType="appointments"
          loadInvites={load}
          inviteStatus={activeTab}
          invites={items}
          loadMore={loadMore}
          loadMoreUrl={loadMoreUrl}
        />
      </div>
    </div>
  );
}
Invites.propTypes = {
  setActiveTab: PropTypes.func,
  load: PropTypes.func,
  activeTab: PropTypes.string,
  loadMore: PropTypes.func,
  loadMoreUrl: PropTypes.string,
  isLoading: PropTypes.bool,
  items: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default compose(
  wrapProvider(store),
  withState("activeTab", "setActiveTab", "open"),
  connect(
    (state) => ({
      invites: invitesSelector(state),
      participations: participationsSelector(state),
      isLoadingInvites: invitesLoadingSelector(state),
      isLoadingParticipants: participantsLoadingSelector(state),
      loadMoreParticipationsUrl: loadMoreUrlParticipationsSelector(state),
      loadMoreInvitesUrl: loadMoreUrlInvitesSelector(state),
    }),
    (dispatch, { activeTab }) => {
      return {
        loadInvites: () =>
          dispatch(
            fetchInvites({
              filter: "appointments",
              status: activeTab,
            }),
          ),
        loadParticipations: () =>
          dispatch(
            fetchParticipations({
              status: activeTab,
            }),
          ),
        onLoadMoreInvites: ({ url }) => dispatch(loadMoreInvites({ url })),
        onLoadMoreParticipations: ({ url }) => {
          dispatch(loadMoreParticipations({ url }));
        },
      };
    },
  ),
  withHandlers({
    loadMore:
      ({
        onLoadMoreInvites,
        onLoadMoreParticipations,
        loadMoreInvitesUrl,
        loadMoreParticipationsUrl,
        activeTab,
      }) =>
      (e) => {
        e.preventDefault();

        return activeTab === "open" ||
          activeTab === "expired" ||
          activeTab === "obsolete"
          ? onLoadMoreInvites({ url: loadMoreInvitesUrl })
          : onLoadMoreParticipations({ url: loadMoreParticipationsUrl });
      },
    load:
      ({ activeTab, loadParticipations, loadInvites }) =>
      () =>
        activeTab === "open" ||
        activeTab === "expired" ||
        activeTab === "obsolete"
          ? loadInvites()
          : loadParticipations(),
  }),
  withProps(
    ({
      activeTab,
      invites,
      participations,
      loadMoreInvitesUrl,
      loadMoreParticipationsUrl,
      isLoadingInvites,
      isLoadingParticipants,
    }) => ({
      loadMoreUrl:
        activeTab === "open" ||
        activeTab === "expired" ||
        activeTab === "obsolete"
          ? loadMoreInvitesUrl
          : loadMoreParticipationsUrl,
      items:
        activeTab === "open" ||
        activeTab === "expired" ||
        activeTab === "obsolete"
          ? { type: "invites", data: invites }
          : { type: "participations", data: participations },
      isLoading:
        activeTab === "open" ||
        activeTab === "expired" ||
        activeTab === "obsolete"
          ? isLoadingInvites
          : isLoadingParticipants,
    }),
  ),
)(Invites);
