import React from "react";
import { useStore, useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import { get } from "lodash";
import MembershipForm from "components/memberships/MembershipForm";
import persistItem from "helpers/items/persistItem";
import { selectNetworkId } from "selectors/environment";
import { membershipRequestSelector } from "selectors/memberships";
import { updateMembership } from "actions/administration/memberships";
import { fetchMembership } from "actions/memberships";
import ControlGroup from "../shared/fields/ControlGroup";
import SimpleInput from "components/appCreator/items/form/SimpleInput";
import useApiResource from "../shared/hooks/useApiResource";
import { useParams } from "helpers/tixxt-router";

type MemberEditFieldsProps = {
  membershipId?: string;
};

function MemberEditFields({ membershipId }: MemberEditFieldsProps) {
  const { loading, data: membership } = useApiResource({
    actionCreator: fetchMembership,
    payload: { membershipId },
    selector: membershipRequestSelector,
  });

  return (
    <ControlGroup
      name="membership_email"
      label={I18n.t("js.administration.members.new.email")}
      htmlFor="membership-email"
    >
      {loading ? (
        <i className="fa fa-spin fa-spinner" />
      ) : (
        <SimpleInput
          meta={{}}
          input={{
            type: "text",
            name: "membership_email",
            id: "membership-email",
            value: get(membership, ["email"]),
            disabled: true,
          }}
        />
      )}
    </ControlGroup>
  );
}

function MembershipEdit() {
  const { membershipId } = useParams();
  const networkId = useSelector(selectNetworkId);
  const externalAppId = `profile:${networkId}`;
  const externalItemId = membershipId;
  const store = useStore();

  const onSubmit = async (values, dispatch) => {
    await persistItem(store, { externalAppId, externalItemId, values });

    const membershipResult = await dispatch(updateMembership({ membershipId }));
    if (membershipResult.error)
      throw new SubmissionError(membershipResult.error);
  };

  const onSubmitSuccess = () => {
    location.replace(`/administration/members`);
    toastr.success(I18n.t("js.saving_successful"));
  };

  return (
    <MembershipForm
      key={membershipId}
      externalAppId={externalAppId}
      externalItemId={externalItemId}
      onSubmit={onSubmit}
      renderExtraFields={() => <MemberEditFields membershipId={membershipId} />}
      onSubmitSuccess={onSubmitSuccess}
      title={I18n.t("js.administration.members.edit.page_title")}
    />
  );
}

export default MembershipEdit;
