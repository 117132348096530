import React from "react";

import TixxtModuleSwitcher from "components/layout/TixxtModuleSwitcher";
import {
  ContextNavigation,
  UserbarLink,
  UserbarSection,
} from "components/layout/Userbar";
import { map } from "lodash";

export default function InfoUserbar() {
  const legalTexts = Preload.current_network.legal_texts;
  const additionalLinks = Preload.current_network.additional_links;

  return (
    <>
      {!window.isApp && <TixxtModuleSwitcher activeModule={"info"} />}
      <ContextNavigation>
        <UserbarSection title={I18n.t("js.userbar.content_section.title")}>
          <UserbarLink to="/imprint">
            {I18n.t("js.layouts.userbar.imprint.title")}
          </UserbarLink>
          {map(legalTexts, (legalText) => (
            <UserbarLink key={legalText.path} to={legalText.path}>
              {legalText.name}
            </UserbarLink>
          ))}
          {map(additionalLinks, (link) => (
            <UserbarLink key={link.href} to={link.href} target="_blank">
              {link.text}
            </UserbarLink>
          ))}
        </UserbarSection>
      </ContextNavigation>
    </>
  );
}
