import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  getUnreadChatsCount,
  getUnreadChatsCountWithoutChat,
} from "../../../selectors/chat/chat";
import Notification from "./Notification";

const BackButton = ({ onClick, counter }) => (
  <button
    onClick={onClick}
    className="btn btn-light btn-sm backButton relative"
  >
    <i className="fa fa-arrow-left" />
    <Notification counter={counter} className={"!absolute"} />
  </button>
);
BackButton.propTypes = {
  counter: PropTypes.number,
  chatId: PropTypes.string,
};

const BackToRootButton = connect(
  (state, { chatId }) => ({
    counter: chatId
      ? getUnreadChatsCountWithoutChat(state, { chatId })
      : getUnreadChatsCount(state),
  }),
  (dispatch) => ({
    onClick: () =>
      dispatch({ type: "chat/NAVIGATE", payload: { route: "root" } }),
  }),
)(BackButton);

export { BackToRootButton };

export default BackButton;
