import { fetchApi } from "helpers/reactQueryApi";

export const deleteGroup = async ({ slug }) => {
  const response = await fetchApi(`/groups/${slug}`, {
    method: "DELETE",
  });

  return response;
};

export const archiveGroup = async ({ slug, archived }) => {
  const response = await fetchApi(`/groups/${slug}/archive`, {
    method: archived ? "DELETE" : "PUT",
  });

  return response;
};
