import React from "react";
import { get, map } from "lodash";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import ControlGroup from "../../shared/fields/ControlGroup";
import SelectionField from "../../appCreator/properties/edit/SelectionField";
import CheckboxField from "components/shared/fields/CheckboxField";
import { Controls } from "components/shared/fields/ControlGroup";
import AddressField from "components/appCreator/properties/edit/AddressField";
import TextField from "../../appCreator/properties/edit/TextField";
import NumberField from "../../appCreator/properties/edit/NumberField";
import { State } from "@types";

interface MemberSettings {
  maps_enabled?: string;
  show_map?: string;
  map_scope_selection?: string;
  two_factor?: string;
  anonymize_memberships_config: string;
  initial_anonymize_memberships_config: string;
  anonymize_memberships_managed_by_admin: boolean;
  show_locked_memberships_managed_by_admin: boolean;
  anonymize_memberships_after_days: number;
  deleted_membership_count: number;
  available_factor_types?: {
    two_factor_email_available: boolean;
    two_factor_totp_available: boolean;
  };
}

function MemberSettings({
  show_map,
  map_scope_selection,
  maps_enabled,
  two_factor,
  anonymize_memberships_config,
  initial_anonymize_memberships_config,
  anonymize_memberships_managed_by_admin,
  show_locked_memberships_managed_by_admin,
  anonymize_memberships_after_days,
  deleted_membership_count,
  available_factor_types,
}: MemberSettings) {
  const twoFactorOptions = [
    {
      value: "disabled",
      label: I18n.t("js.administration.authentication.2fa.deactivated"),
    },
    {
      value: "enabled",
      label: I18n.t("js.administration.authentication.2fa.optional"),
    },
  ];

  if (Tixxt.currentNetwork.getConfig("two_factor.enforced_available"))
    twoFactorOptions.push({
      value: "enforced",
      label: I18n.t(
        "js.administration.authentication.2fa.enforced_for_network",
      ),
    });

  return (
    <>
      <h3>
        {I18n.t("js.administration.network_settings.member_settings.title")}
      </h3>

      <h4 className="font-bold">
        {I18n.t("js.administration.network_settings.member_settings.directory")}
      </h4>

      <div className="flex flex-col gap-4">
        <ControlGroup
          name="directory_default_sort"
          label={I18n.t("js.networks.directory_sort.label")}
          className="white-box checkboxes border-box p-3"
        >
          <SelectionField
            name="directory_default_sort"
            multiple={false}
            select={false}
            required
            options={[
              {
                value: "first_name",
                label: I18n.t("js.networks.directory_sort.first_name"),
              },
              {
                value: "last_name",
                label: I18n.t("js.networks.directory_sort.last_name"),
              },
            ]}
          />
        </ControlGroup>

        {maps_enabled === "enabled" ? (
          <>
            <ControlGroup
              name="member_map"
              label={I18n.t(
                "js.administration.network_settings.member_settings.map.label",
              )}
              className="white-box checkboxes border-box p-3"
            >
              <SelectionField
                name="show_map"
                multiple={false}
                select={false}
                required
                options={[
                  {
                    value: "enabled",
                    label: I18n.t(
                      "js.administration.network_settings.member_settings.map.enabled",
                    ),
                  },
                  {
                    value: "disabled",
                    label: I18n.t(
                      "js.administration.network_settings.member_settings.map.disabled",
                    ),
                  },
                ]}
              />
              <div className="help-block mt-1 !mb-3">
                {I18n.t("js.administration.network_settings.map_modal_body")}
              </div>
              {show_map === "enabled" ? (
                <>
                  <hr className="mb-2" />
                  <div>
                    <span>
                      {I18n.t(
                        "js.administration.network_settings.member_settings.map.map_scope_selection.label",
                      )}
                    </span>
                    <div className="pt-1">
                      <SelectionField
                        name="map_scope_selection"
                        multiple={false}
                        select={false}
                        required
                        options={[
                          {
                            value: "all",
                            label: I18n.t(
                              "js.administration.network_settings.member_settings.map.map_scope_selection.all",
                            ),
                          },
                          {
                            value: "poi",
                            label: I18n.t(
                              "js.administration.network_settings.member_settings.map.map_scope_selection.poi",
                            ),
                          },
                        ]}
                      />
                    </div>
                    {map_scope_selection === "poi" ? (
                      <div className="pt-1 flex flex-col gap-2">
                        <div>
                          <span>
                            {I18n.t(
                              "js.administration.network_settings.member_settings.map.map_scope_selection.address_label",
                            )}
                          </span>
                          <AddressField
                            className="flex-auto w-64"
                            name="network_location"
                            required={{
                              street: true,
                              number: true,
                              zip: true,
                              city: true,
                            }}
                          />
                          <span className="-mt-[20px]">
                            {I18n.t(
                              "js.administration.network_settings.member_settings.map.map_scope_selection.zoom",
                            )}
                          </span>
                          <SelectionField
                            name="zoom_level"
                            multiple={false}
                            select={true}
                            required
                            options={[
                              {
                                value: "0",
                                label: I18n.t(
                                  "js.administration.network_settings.member_settings.map.map_scope_selection.earth",
                                ),
                              },
                              {
                                value: "3",
                                label: I18n.t(
                                  "js.administration.network_settings.member_settings.map.map_scope_selection.continent",
                                ),
                              },
                              {
                                value: "6",
                                label: I18n.t(
                                  "js.administration.network_settings.member_settings.map.map_scope_selection.rivers",
                                ),
                              },
                              {
                                value: "10",
                                label: I18n.t(
                                  "js.administration.network_settings.member_settings.map.map_scope_selection.roads",
                                ),
                              },
                              {
                                value: "15",
                                label: I18n.t(
                                  "js.administration.network_settings.member_settings.map.map_scope_selection.buildings",
                                ),
                              },
                            ]}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <hr className="my-2" />
                  {I18n.t(
                    "js.administration.network_settings.member_settings.map.theme.label",
                  )}
                  <SelectionField
                    name="map_theme"
                    multiple={false}
                    select={false}
                    required
                    options={[
                      {
                        value: "alidade_smooth",
                        label: (
                          <img
                            className="w-56 pb-1"
                            src="/static/map/alidade_smooth.png"
                            alt="alidade_smooth"
                          />
                        ),
                      },
                      {
                        value: "alidade_smooth_dark",
                        label: (
                          <img
                            className="w-56 pb-1"
                            src="/static/map/alidade_smooth_dark.png"
                            alt="alidade_smooth_dark"
                          />
                        ),
                      },
                      {
                        value: "osm_bright",
                        label: (
                          <img
                            className="w-56 pb-1"
                            src="/static/map/osm_bright.png"
                            alt="osm_bright"
                          />
                        ),
                      },
                      {
                        value: "outdoors",
                        label: (
                          <img
                            className="w-56"
                            src="/static/map/outdoors.png"
                            alt="outdoors"
                          />
                        ),
                      },
                    ]}
                  />
                </>
              ) : null}
            </ControlGroup>
          </>
        ) : null}
        <ControlGroup
          name="directory_profile_fields"
          label={I18n.t("js.networks.directory_fields.label")}
        >
          <SelectionField
            name="directory_profile_fields"
            multiple
            options={map(
              Tixxt.currentNetwork.get("profile_fields") as Array<{
                name: string;
                label: string;
              }>,
              (field) => ({
                value: field.name,
                label: field.label,
              }),
            )}
          />
          <div className="help-block mt-1 !mb-3">
            {I18n.t("js.networks.directory_fields.help")}
          </div>
        </ControlGroup>

        <ControlGroup
          name="detection_profile_field"
          label={I18n.t("js.networks.detection_profile_field.label")}
        >
          <SelectionField
            name="detection_profile_field"
            multiple={false}
            options={map(
              Tixxt.currentNetwork.get(
                "profile_fields_without_multiline",
              ) as Array<{
                name: string;
                label: string;
              }>,
              (field) => ({
                value: field.name,
                label: field.label,
              }),
            )}
          />
          <div className="help-block mt-1 !mb-3">
            {I18n.t("js.networks.detection_profile_field.hint")}
          </div>
        </ControlGroup>
        {show_locked_memberships_managed_by_admin ? (
          <ControlGroup
            name="show_locked_memberships"
            label={"Gesperrte Nutzer im Nutzerverzeichnis anzeigen?"}
            className="white-box checkboxes border-box p-3"
          >
            <SelectionField
              name="show_locked_memberships"
              multiple={false}
              select={false}
              required
              options={[
                {
                  value: "enabled",
                  label: "Ja",
                },
                {
                  value: "disabled",
                  label: "Nein",
                },
              ]}
            />
          </ControlGroup>
        ) : null}

        {anonymize_memberships_managed_by_admin ? (
          <>
            <ControlGroup
              name="anonymize_memberships"
              label={I18n.t(
                "js.administration.network_settings.anonymize_memberships.title",
              )}
              className="white-box checkboxes border-box p-3"
            >
              {["enabled", "after_days"].includes(
                anonymize_memberships_config,
              ) && initial_anonymize_memberships_config == "disabled" ? (
                <div className="alert alert-error">
                  {I18n.t(
                    "js.administration.network_settings.anonymize_memberships.warning",
                    { deletedMemberCount: deleted_membership_count },
                  )}
                </div>
              ) : null}
              <SelectionField
                name="anonymize_memberships_config"
                multiple={false}
                select={false}
                required
                options={[
                  {
                    value: "enabled",
                    label: I18n.t(
                      "js.administration.network_settings.anonymize_memberships.enabled",
                    ),
                  },
                  {
                    value: "after_days",
                    label: I18n.t(
                      "js.administration.network_settings.anonymize_memberships.after_x_days",
                      { days: anonymize_memberships_after_days },
                    ),
                  },
                  {
                    value: "disabled",
                    label: I18n.t(
                      "js.administration.network_settings.anonymize_memberships.disabled",
                    ),
                  },
                ]}
              />
              {anonymize_memberships_config === "after_days" ? (
                <>
                  <hr className="my-2" />
                  {I18n.t(
                    "js.administration.network_settings.anonymize_memberships.days.title",
                  )}
                  <NumberField
                    name="anonymize_memberships_after_days"
                    required
                  />
                </>
              ) : null}
              {["enabled", "after_days"].includes(
                anonymize_memberships_config as string,
              ) ? (
                <>
                  <hr className="my-2" />
                  {I18n.t(
                    "js.administration.network_settings.anonymize_memberships.name.title",
                  )}
                  <TextField name="anonymize_memberships_name" required />
                </>
              ) : null}
            </ControlGroup>
          </>
        ) : null}

        <h4 className="font-bold">
          {I18n.t(
            "js.administration.network_settings.member_settings.security",
          )}
        </h4>

        <ControlGroup
          name="two_factor"
          label={I18n.t("js.authentication.2fa.page_title")}
          className="white-box checkboxes border-box p-3"
        >
          <SelectionField
            name="two_factor"
            multiple={false}
            select={false}
            required
            options={twoFactorOptions}
          />
          {Tixxt.currentNetwork.getConfig("two_factor.enforced_available") ? (
            <div className="help-block mt-1">
              {I18n.t(
                "js.administration.authentication.2fa.enforced_description",
              )}
            </div>
          ) : null}

          {two_factor != "disabled" ? (
            <div className="grid grid-cols-2 mt-2 gap-4">
              <Controls
                wrapperClassName="members-can-invite input boolean col-span-1"
                className={"flex"}
              >
                <CheckboxField
                  name={`two_factor_totp_available`}
                  label={""}
                  type="checkbox"
                  disabled={
                    available_factor_types?.two_factor_email_available == false
                  }
                />
                <div key="totp" className="border-box flex-1 p-2">
                  <div className="media-body flex flex-col gap-2">
                    <div className="flex gap-2 items-center">
                      <i className="fa-regular fa-mobile text-2xl" />
                      <div className="flex grow justify-between gap-2 items-center">
                        <span className="font-semibold">
                          {I18n.t("js.authentication.2fa.authenticator_app")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Controls>
              <Controls
                wrapperClassName="members-can-invite input boolean col-span-1"
                className={"flex"}
              >
                <CheckboxField
                  name={`two_factor_email_available`}
                  label={""}
                  type="checkbox"
                  disabled={
                    available_factor_types?.two_factor_totp_available == false
                  }
                />
                <div key="email" className="border-box flex-1 p-2">
                  <div className="media-body flex flex-col gap-2">
                    <div className="flex gap-2 items-center">
                      <i className={"text-2xl fa-regular fa-envelope"} />
                      <div className="flex grow justify-between gap-2 items-center">
                        <span className="font-semibold">
                          {I18n.t("js.authentication.2fa.email")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Controls>
            </div>
          ) : null}
        </ControlGroup>
      </div>
    </>
  );
}

const selector = formValueSelector("networkSettingsForm"); // the name of the outer form
export default connect((state) => ({
  maps_enabled: selector(state as State, "maps_enabled"),
  show_map: selector(state as State, "show_map"),
  map_scope_selection: selector(state as State, "map_scope_selection"),
  two_factor: selector(state as State, "two_factor"),
  deleted_membership_count: selector(
    state as State,
    "deleted_membership_count",
  ),
  anonymize_memberships_managed_by_admin: selector(
    state as State,
    "anonymize_memberships_managed_by_admin",
  ),
  show_locked_memberships_managed_by_admin: selector(
    state as State,
    "show_locked_memberships_managed_by_admin",
  ),
  initial_anonymize_memberships_config: get(state, [
    "form",
    "networkSettingsForm",
    "initial",
    "anonymize_memberships_config",
  ]),
  anonymize_memberships_config: selector(
    state as State,
    "anonymize_memberships_config",
  ),
  anonymize_memberships_after_days: selector(
    state as State,
    "anonymize_memberships_after_days",
  ),

  available_factor_types: {
    two_factor_totp_available: selector(
      state as State,
      "two_factor_totp_available",
    ),
    two_factor_email_available: selector(
      state as State,
      "two_factor_email_available",
    ),
  },
}))(MemberSettings);
