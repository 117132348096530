import React from "react";
import { SubmissionError } from "redux-form";
import { useStore } from "react-redux";
import { isEmpty } from "lodash";
import { useParams } from "helpers/tixxt-router";
import GroupForm from "./form";
import EditGroupActions from "./EditGroupActions";
import { fetchGroupRequestSelector } from "selectors/environment";
import useApiResource from "components/shared/hooks/useApiResource";
import { fetchGroup, updateGroup } from "actions/groups";
import persistItem from "helpers/items/persistItem";
import TixxtPageTitle from "../layout/TixxtPageTitle";

export default function EditGroup() {
  const { groupSlug } = useParams();
  const { data: group } = useApiResource({
    actionCreator: fetchGroup,
    payload: { groupSlug },
    selector: fetchGroupRequestSelector,
  });
  const store = useStore();

  const onSubmit = async (
    values,
    dispatch,
    { externalAppId, externalItemId },
  ) => {
    const { profile, ...group } = values;

    await persistItem(store, {
      externalAppId,
      externalItemId,
      values: profile,
    });

    const groupResult = await dispatch(
      updateGroup({ groupSlug, body: { group } }),
    );
    if (groupResult.error) throw new SubmissionError();

    return groupResult;
  };

  return (
    <>
      <TixxtPageTitle />
      {!isEmpty(group) ? (
        <>
          {group.can.archive ? <EditGroupActions {...group} /> : null}
          {!group.archived ? (
            <GroupForm
              {...group}
              onSubmit={onSubmit}
              formName={`group-${group.id}`}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
}
