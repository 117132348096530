import React, { useRef, useEffect } from "react";
import "../../../../vendor/assets/javascripts/spectrum/spectrum.css";

interface SpectrumProps {
  value: string;

  onChange(...args: unknown[]): unknown; // Implementation is in .coffee
}

function Spectrum(props: SpectrumProps) {
  const ref = useRef(null);
  let el;

  useEffect(() => {
    if (!el && ref.current) {
      el = $(ref.current);
      el.spectrum({
        showInput: true,
        preferredFormat: "hex",
        showInitial: true,
        cancelText: I18n.t("plugins.spectrum.cancel"),
        chooseText: I18n.t("plugins.spectrum.choose"),
        change: (color) =>
          props.onChange({
            target: {
              value: color.toHexString(),
            },
          }),
        color: props.value,
      });
    } else {
      el.spectrum("set", props.value);
    }
    return () => el.spectrum("destroy");
  }, [ref.current, props.value]);

  return (
    <div>
      <input
        type="color"
        ref={ref}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
}

export default Spectrum;
