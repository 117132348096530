import { get, find, isEqual, orderBy, reduce, findLast } from "lodash";
import { createSelector } from "reselect";
import moment from "moment";
import { getProfileId, getImageUrl } from "./application";
import { getHomie } from "./homies";

export const getChats = (state) => get(state, ["chat", "chats"]);

export const getSortedChats = createSelector(getChats, (chats) =>
  orderBy(chats, "last_interacted_at", ["desc"]),
);

export const getRawChatProfile = (state, { chatId }) =>
  get(state, ["chat", "chats", chatId]);

export const getChatProfile = (state, { chatId }) => {
  const chat = getRawChatProfile(state, { chatId });
  const [type] = chatId.split("/");
  const imageUrl = getChatImageUrl(state, chatId);

  return {
    ...chat,
    type,
    imageUrl,
  };
};

export const getStateForChat = (state, { chatId }) =>
  get(getRawChatProfile(state, { chatId }), ["state"]);

export const getCounterForChat = (state, { chatId }) =>
  get(getStateForChat(state, { chatId }), ["unread_count"]);

export const getLastReadIdForChat = (state, { chatId }) =>
  get(getStateForChat(state, { chatId }), ["last_read_id"]);

export const getMutedUntilForChat = (state, { chatId }) =>
  get(getStateForChat(state, { chatId }), ["muted_until"]);

export const getAppMutedUntilForChat = (state, { chatId }) =>
  get(getStateForChat(state, { chatId }), ["app_muted_until"]);

// not pure !
export const isMuted = (state, { chatId }) => {
  const mutedUntil = window.isApp
    ? getAppMutedUntilForChat(state, { chatId })
    : getMutedUntilForChat(state, { chatId });
  return mutedUntil && moment().isBefore(mutedUntil);
};

export const getAggregatedUnreadCount = (state) =>
  reduce(
    getChats(state),
    (result, chat) => result + get(chat, ["state", "unread_count"], 0),
    0,
  );

export const getAggregatedUnreadCountWithoutChat = (state, { chatId }) =>
  reduce(
    getChats(state),
    (result, chat) =>
      chat.id == chatId
        ? result
        : result + get(chat, ["state", "unread_count"], 0),
    0,
  );

export const getUnreadChatsCount = (state) =>
  reduce(
    getChats(state),
    (result, chat) => {
      if (get(chat, ["state", "unread_count"], 0) > 0) {
        return result + 1;
      }
      return result;
    },
    0,
  );

export const getUnreadChatsCountWithoutChat = (state, { chatId }) =>
  reduce(
    getChats(state),
    (result, chat) => {
      if (chatId !== chat.id && get(chat, ["state", "unread_count"], 0) > 0) {
        return result + 1;
      }
      return result;
    },
    0,
  );

// Return the full url to the image of a chat if possible
export const getChatImageUrl = (state, chatId) => {
  const rawProfile = getRawChatProfile(state, { chatId });
  if (rawProfile) {
    return getImageUrl(state, rawProfile.image_id, "80x80");
  }
  return null;
};

// Returns true if this "chat" is online. This currently can only happen for member 1:1 chats
export const getChatOnlineStatus = (state, chatId) => {
  const partnerId = getPartnerId(state, chatId);
  return partnerId ? getMemberOnlineStatus(state, partnerId) : null;
};

// Returns true if this "chat" is busy. This currently can only happen for member 1:1 chats
export const getChatBusyStatus = (state, chatId) => {
  const partnerId = getPartnerId(state, chatId);
  return partnerId ? getMemberBusyStatus(state, partnerId) : null;
};

// Turn chatId "member/123:345" into "345" if "123" is your id
export const getPartnerId = (state, chatId) => {
  const [type, idRest] = chatId.split("/");
  if (type === "member") {
    const myId = getProfileId(state);
    return find(idRest.split(":"), (e) => !isEqual(e, myId));
  }
  return null;
};

export const getMemberOnlineStatus = (state, membershipId) => {
  const timestamps = get(state, [
    "chat",
    "presence",
    "onlineMembers",
    membershipId,
  ]);
  return timestamps && timestamps.length > 0;
};

export const getMemberBusyStatus = (state, membershipId) => {
  return get(state, ["chat", "presence", "busyMembers", membershipId]);
};

export const getRequestStateForChat = (state, { chatId }) =>
  get(getRawChatProfile(state, { chatId }), ["requestState"], {});

export const getMoreMessagesAvailableForChat = (state, { chatId }) =>
  get(
    getRequestStateForChat(state, { chatId }),
    ["moreMessagesAvailable"],
    true,
  );

// Gets a known homie which has recently started composing in given chatId
export const getComposingHomie = (state, { chatId }) => {
  const composingIds = get(state, ["chat", "composing", chatId]);
  const myId = getProfileId(state);
  const recentlyComposingHomieId = findLast(
    composingIds,
    (id) => id !== myId && getHomie(state, id),
  );
  if (!recentlyComposingHomieId) {
    return null;
  }
  return getHomie(state, recentlyComposingHomieId);
};
