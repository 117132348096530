import React, { Fragment } from "react";
import { useSelector, useStore } from "react-redux";
import { isEmpty } from "lodash";
import { SubmissionError } from "redux-form";
import { useParams } from "helpers/tixxt-router";

import PageTitle from "components/layout/PageTitle";
import GroupForm from "./form";
import {
  groupCategorySelector,
  groupDefaultsSelector,
} from "selectors/environment";
import { fetchGroupDefaults, createGroup } from "actions/groups";
import useApiResource from "components/shared/hooks/useApiResource";
import persistItem from "helpers/items/persistItem";

export default function NewGroup() {
  const { groupCategorySlug } = useParams();
  const category = useSelector((state) =>
    groupCategorySelector(state, groupCategorySlug),
  );
  const store = useStore();

  const { loading, data: groupDefaults } = useApiResource({
    actionCreator: fetchGroupDefaults,
    payload: { groupCategorySlug },
    selector: groupDefaultsSelector,
  });

  const onSubmit = async (values, dispatch, { externalAppId }) => {
    const { profile, ...group } = values;

    const { id: itemId } = await persistItem(store, {
      externalAppId,
      values: profile,
    });

    const groupResult = await dispatch(
      createGroup({
        body: { group: { ...group, profile_id: itemId } },
      }),
    );
    if (groupResult.error) throw new SubmissionError();

    return groupResult;
  };

  return (
    <Fragment>
      <PageTitle
        title={category.name}
        subtitle={I18n.t("js.groups.new.create")}
      />
      {!isEmpty(groupDefaults) && !loading ? (
        <GroupForm
          {...groupDefaults}
          onSubmit={onSubmit}
          group_category_id={category.id}
          groupCategoryName={category.name}
          formName={`group-new`}
        />
      ) : loading ? (
        <i className="fa-regular fa-spinner fa-spin" />
      ) : null}
    </Fragment>
  );
}
