import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { map, isEmpty } from "lodash";
import AppointmentInvite from "../appointments/AppointmentInvite";
import AppointmentParticipation from "../appointments/AppointmentParticipation";
import CustomConfigurator from "../../CustomConfigurator";

if (!CustomConfigurator.existsDefault("invites.types")) {
  CustomConfigurator.setDefault("invites.types", {
    appointments: {
      invites: {
        Appointment: AppointmentInvite,
      },
      participations: {
        default: AppointmentParticipation,
      },
    },
  });
}

export default function InvitesList({
  invites,
  inviteType,
  inviteStatus,
  isLoading,
  loadMore,
  loadMoreUrl,
  loadInvites,
}) {
  useEffect(() => {
    loadInvites();
  }, [inviteStatus]);

  return (
    <div>
      {isLoading ? (
        <div className="mt-2">
          {I18n.t("js.calendars.appointments.invites.loading")}
        </div>
      ) : !isEmpty(invites.data) && !isLoading ? (
        <div className="media-list">
          {map(invites.data, (invite, index) => {
            const path = [
              "invites",
              "types",
              inviteType,
              invites.type,
              (invites.type === "invites"
                ? invite.invitable_type
                : invite._type) || "default",
            ];
            const Component =
              CustomConfigurator.get(path) ||
              (() => `Missing component for ${path.join(".")}`);

            return (
              <Component inviteStatus={inviteStatus} key={index} {...invite} />
            );
          })}
        </div>
      ) : (
        <div className="alert alert-info mt-4">
          {I18n.t(`js.calendars.appointments.invites.no_${inviteStatus}`)}
        </div>
      )}
      {!isEmpty(loadMoreUrl) && !isLoading ? (
        <div className="appointment-invites-show-more-container">
          <a className="btn" onClick={loadMore} href="#">
            <i className="fa fa-arrow-down" />{" "}
            {I18n.t("js.calendars.appointments.invites.show_more")}
          </a>
        </div>
      ) : null}
    </div>
  );
}

InvitesList.propTypes = {
  invitableType: PropTypes.string,
  inviteType: PropTypes.string,
  invites: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  inviteStatus: PropTypes.string,
  loadMore: PropTypes.func,
  loadMoreUrl: PropTypes.string,
  isLoading: PropTypes.bool,
};
