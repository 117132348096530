import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "helpers/usePrevious";
import { map, get, isEmpty, filter } from "lodash";

import MemberSelect from "../../shared/MemberSelect";
import { addParticipations } from "../../../actions/participations";

function NewParticipantForm({
  invite_consumers,
  reloadAppointment,
  id,
  newParticipants,
}) {
  const dispatch = useDispatch();
  const [reloading, setReloading] = useState(false);
  const [participantsStatus, setParticipantsStatus] = useState("open");
  const [emailNotification, setEmailNotification] = useState(true);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [inviteConsumers, setInviteConsumers] = useState(invite_consumers);
  const isLoading = useSelector(
    (state) => get(state, "appointments.participantsRequests.loading") || false,
  );
  const addedParticipants = useSelector((state) =>
    get(state, "appointments.participantsRequests.data"),
  );
  const prevParticipants = usePrevious(addedParticipants);
  const prevParticipantsStatus = usePrevious(participantsStatus);

  const addParticipantsHandler = ({ memberIds, groupIds, membershipRoleIds }) =>
    dispatch(
      addParticipations({
        appointmentId: id,
        body: {
          participant: {
            membership_ids: memberIds,
            group_ids: groupIds,
            membership_role_ids: membershipRoleIds,
            status: participantsStatus,
            send_email_notification: emailNotification,
            invite_consumers: inviteConsumers,
          },
        },
      }),
    );

  useEffect(() => {
    if (!isEmpty(addedParticipants) && addedParticipants !== prevParticipants) {
      newParticipants(addedParticipants, prevParticipantsStatus);
      setParticipantsStatus("open");
    }
  });

  return (
    <div className="border-box mb-4 p-3">
      <form className="form-horizontal" onSubmit={onAddParticipants}>
        <div className="control-group member-select">
          <label className="control-label">
            {I18n.t("js.calendars.appointment.participations.add")}
          </label>
          <div className="controls contacts-container member-select-control mt-2">
            <div className="invites-select-view mb-2">
              <MemberSelect
                multi={true}
                onChange={onSetSelectedEntities}
                value={selectedEntities}
                membersAndGroups
                includeMembershipRoles
              />
            </div>
            <div className="flex flex-col">
              <select
                className="invites-status-view pull-left mb-2"
                onChange={onChangeParticipantsStatus}
                value={participantsStatus}
              >
                <option value="open">
                  {I18n.t("js.calendars.appointment.participant_status.open")}
                </option>
                <option value="accepted">
                  {I18n.t(
                    "js.calendars.appointment.participant_status.accepted",
                  )}
                </option>
                <option value="declined">
                  {I18n.t(
                    "js.calendars.appointment.participant_status.declined",
                  )}
                </option>
              </select>
              <label className="mail-notifications">
                <input
                  className="mail-notification-checkbox mr-2"
                  type="checkbox"
                  name="email"
                  value="email"
                  onChange={() => setEmailNotification(!emailNotification)}
                  checked={emailNotification}
                />
                <span className="mail-notifications-label">
                  {I18n.t(
                    "js.calendars.appointment.participations.send_notification",
                  )}
                </span>
              </label>
              {Tixxt.currentNetwork.getConfig("consumers_enabled") ? (
                <label className="invite-consumers">
                  <input
                    className="invite-consumers-checkbox mr-2"
                    type="checkbox"
                    name="invite_consumers"
                    onChange={() => setInviteConsumers(!inviteConsumers)}
                    checked={inviteConsumers}
                  />
                  <span className="invite-consumers-label">
                    {I18n.t(
                      "js.calendars.appointment.invites.invite_consumers",
                    )}
                  </span>
                </label>
              ) : null}
            </div>
          </div>
        </div>
        <div className="new-participants-send-button flex flex-row justify-end items-center gap-2 mt-4">
          {isLoading || reloading ? (
            <i className="fa-regular fa-spinner fa-spin text-muted add-participants-loading-icon" />
          ) : null}
          <button
            type="submit"
            className="btn btn-success"
            disabled={isLoading}
          >
            {I18n.t("js.calendars.appointment.add_participant.send_title")}
          </button>
        </div>
      </form>
    </div>
  );

  function onChangeParticipantsStatus(e) {
    return setParticipantsStatus(e.target.value);
  }
  function onSetSelectedEntities(members) {
    return setSelectedEntities(members);
  }
  function onAddParticipants(e) {
    e.preventDefault();

    const memberIds = map(
      filter(selectedEntities, (entity) => entity.type === "member"),
      (m) => m.value,
    );

    const groupIds = map(
      filter(selectedEntities, (entity) => entity.type === "group"),
      (g) => g.value,
    );

    const membershipRoleIds = map(
      filter(selectedEntities, (entity) => entity.type === "membershipRole"),
      (mr) => mr.value,
    );

    addParticipantsHandler({
      memberIds,
      groupIds,
      membershipRoleIds,
    });
    setSelectedEntities([]);
    setEmailNotification(true);

    // Sorry, dirty toastr stuff...
    const oldOptions = toastr.options;
    toastr.options = Object.assign(oldOptions, {
      timeOut: 0,
      extendedTimeOut: 0,
      tapToDismiss: false,
    });

    const $toast = toastr.success(
      I18n.t(
        membershipRoleIds.length === 0
          ? "js.calendars.appointment.participations.success_alert"
          : "js.calendars.appointment.participations.success_alert_with_mr",
        {
          member_count: memberIds.length,
          group_count: groupIds.length,
          membership_role_count: membershipRoleIds.length,
        },
      ),
    );

    setReloading(true);

    window.setTimeout(reloadAppointment, 3000);
    window.setTimeout(reloadAppointment, 9000);
    window.setTimeout(() => {
      reloadAppointment();
      setReloading(false);
      toastr.options = oldOptions;
      $toast.fadeOut(300).done(function () {
        $toast.remove();
      });
    }, 18000);
    // ...the end
  }
}

NewParticipantForm.propTypes = {
  id: PropTypes.string,
  invite_consumers: PropTypes.bool,
  reloadAppointment: PropTypes.func,
  newParticipants: PropTypes.func,
};

export default NewParticipantForm;
