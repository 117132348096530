import React, { useState } from "react";
import { memoize } from "lodash";
import ReduxForm from "components/shared/reduxForm";
import BasicSettings from "./BasicSettings";
import JoinSettings from "./JoinSettings";
import MemberSettings from "./MemberSettings";
import MailSettings from "./MailSettings";
import TabBar from "../../shared/TabBar";

const getTabs = memoize(() => [
  {
    label: I18n.t("js.administration.network_settings.basic_settings.title"),
    TabContent: BasicSettings,
  },
  {
    label: I18n.t("js.administration.network_settings.join_settings.title"),
    TabContent: JoinSettings,
  },
  {
    label: I18n.t("js.administration.network_settings.member_settings.title"),
    TabContent: MemberSettings,
  },
  {
    label: I18n.t("js.administration.network_settings.mail_settings.title"),
    TabContent: MailSettings,
  },
]);

interface NetworkSettingsFormTypes {
  initialValues: Record<PropertyKey, unknown>;
  onSubmit(...args: unknown[]): unknown;
}

function NetworkSettingsForm({
  onSubmit,
  initialValues,
}: NetworkSettingsFormTypes) {
  const [selectedTabIndex, selectTabIndex] = useState(0);
  const { TabContent } = getTabs()[selectedTabIndex];

  return (
    <ReduxForm
      form="networkSettingsForm"
      className="form-horizontal boxed-form-fields"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSubmitFail={() => toastr.error(I18n.t("js.saving_failure"))}
      onCancel={(reset: () => unknown) => reset()}
      allowSaveWithoutChange={false}
    >
      <TabBar
        tabs={getTabs()}
        selectedTabIndex={selectedTabIndex}
        selectTabIndex={selectTabIndex}
      />
      <div className="tab-content network-settings">
        <TabContent />
      </div>
    </ReduxForm>
  );
}

export default NetworkSettingsForm;
