import React from "react";
import { useStore, useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import { useParams } from "helpers/tixxt-router";

import { selectNetworkId } from "selectors/environment";
import { updateMembership } from "actions/memberships";
import persistItem from "helpers/items/persistItem";

import MembershipForm from "./MembershipForm";

export default function EditMembership() {
  const { membershipId } = useParams();
  const networkId = useSelector(selectNetworkId);
  const externalAppId = `profile:${networkId}`;
  const externalItemId = membershipId;
  const store = useStore();

  const onSubmit = async (values, dispatch) => {
    await persistItem(store, { externalAppId, externalItemId, values });

    const membershipResult = await dispatch(updateMembership({ membershipId }));
    if (membershipResult.error) throw new SubmissionError();
  };

  const onSubmitSuccess = () => {
    location.replace(`/members/${membershipId}`);
    toastr.success(I18n.t("js.saving_successful"));
  };

  return (
    <MembershipForm
      externalAppId={externalAppId}
      externalItemId={externalItemId}
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      title={I18n.t("js.apps.profile.edit.title")}
    />
  );
}
