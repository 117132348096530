import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";

import { createChat } from "../../../../actions/chat";

const MessageSettings = ({
  setMessageToQuote,
  createPrivateChat,
  myMessage,
  type,
  className,
}) => (
  <div className={classNames("chatMessageSettings", className)}>
    <div className={"dropdown"}>
      <button
        className={"btn btn-light btn-sm leading-none px-1"}
        data-bs-toggle="dropdown"
      >
        <i className="fa fa-ellipsis-h fa-sm" />
      </button>
      <ul className="dropdown-menu">
        <li>
          <a onClick={setMessageToQuote} href={"#"}>
            {I18n.t("js.chat.room.message.quote_message")}
          </a>
        </li>
        {myMessage || type === "member" ? null : (
          <li>
            <a onClick={createPrivateChat} href={"#"}>
              {I18n.t("js.chat.room.message.start_private_chat")}
            </a>
          </li>
        )}
      </ul>
    </div>
  </div>
);

MessageSettings.propTypes = {
  id: PropTypes.string,
  chatId: PropTypes.string,
  myMessage: PropTypes.bool,
  setMessageToQuote: PropTypes.func,
  createPrivateChat: PropTypes.func,
};

export default connect(
  (state, { chatId }) => ({
    type: chatId.indexOf("group") === 0 ? "group" : "member",
  }),
  (dispatch, { id, chatId, fromId }) => ({
    setMessageToQuote: (e) => {
      e.preventDefault();
      dispatch({
        type: "chat/COMPOSER/SET_MESSAGE_TO_QUOTE",
        messageId: id,
        chatId,
      });
    },
    createPrivateChat: (e) => {
      e.preventDefault();
      dispatch(createChat({ payload: { id: fromId, type: "member" } }));
    },
  }),
)(MessageSettings);
