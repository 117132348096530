import { useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

export async function createAppointmentContact({ appointmentId, ...body }) {
  return fetchApi(`/appointments/${appointmentId}/contact`, {
    method: "POST",
    body,
  });
}

export function useCreateAppointmentContact(options) {
  return useMutation(createAppointmentContact, options);
}
