import React, { useState } from "react";
import { useSelector, useStore } from "react-redux";
import { SubmissionError } from "redux-form";

import persistItem from "helpers/items/persistItem";
import { updateProfileStep } from "actions/onboarding";
import { selectNetworkId, currentMemberSelector } from "selectors/environment";
import MembershipForm from "components/memberships/MembershipForm";

function ProfileOnboarding() {
  const networkId = useSelector(selectNetworkId);
  const externalAppId = `profile:${networkId}`;
  const membershipId = useSelector(currentMemberSelector).id;
  const externalItemId = membershipId;
  const store = useStore();
  const [redirectUrl, setRedirectUrl] = useState("/");

  const onSubmit = async (values, dispatch) => {
    await persistItem(store, { externalAppId, externalItemId, values });

    const onboardingResult = await dispatch(updateProfileStep({}));
    if (onboardingResult.error) throw new SubmissionError();
    setRedirectUrl(onboardingResult.redirect_to);
  };

  const onSubmitSuccess = () => {
    window.location.href = redirectUrl;
    toastr.success(I18n.t("js.saving_successful"));
  };

  return (
    <MembershipForm
      externalAppId={externalAppId}
      externalItemId={externalItemId}
      onSubmitSuccess={onSubmitSuccess}
      onSubmit={onSubmit}
      title={I18n.t("js.apps.profile.edit.title")}
      allowSaveWithoutChange
    />
  );
}

export default ProfileOnboarding;
