import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import classnames from "classnames";

type DropDownArgs = {
  children: React.ReactNode;
  alignment?: "start" | "end";
  className?: string;
};

function DropDown({ children, className, alignment = "end" }: DropDownArgs) {
  return (
    <ul
      className={classnames(
        `dropdown-menu dropdown-menu-${alignment}`,
        className,
      )}
    >
      {children}
    </ul>
  );
}

type DropDownItemArgs = {
  children?: React.ReactNode;
  url?: string;
  icon?: IconProp;
  text?: string;
  additional?: React.ReactNode;
  linkStyle?: string;
  method?: string;
  className?: string;
};

export function DropDownItem({
  children,
  url,
  icon,
  text,
  additional,
  linkStyle,
  method,
  className,
}: DropDownItemArgs) {
  return (
    <li className={className}>
      {children ? (
        children
      ) : (
        <a
          href={url || "#"}
          className={classNames("dropdown-item", linkStyle)}
          data-method={method}
        >
          <span className="flex items-center space-x-1">
            {icon && <FontAwesomeIcon icon={icon} className={"fa-fw"} />}
            {text && <span className="flex-1">{text}</span>}
            {additional}
          </span>
        </a>
      )}
    </li>
  );
}

DropDown.Item = DropDownItem;
export default DropDown;
