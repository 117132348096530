import React, { useState } from "react";
import PropTypes from "prop-types";
import { map, filter, get, includes, without, concat, isEmpty } from "lodash";
import classNames from "classnames";

import ParticipantRow from "./ParticipantRow";

export default function ParticipantsOverview({
  participants,
  appointmentId,
  changeStatus,
  deleteParticipant,
  total_attendees_enforced_and_exceeded,
}) {
  const [searchText, setSearchText] = useState("");
  const [activeFilter, setActiveFilter] = useState([]);
  const regex = new RegExp(`(${searchText})`, "gi");

  participants = filter(participants, (participant) =>
    get(participant, "membership.name")
      ? get(participant, "membership.name").match(regex) &&
        (includes(activeFilter, participant.status) || isEmpty(activeFilter))
      : null,
  );

  function onSearch(event) {
    setSearchText(event.target.value);
  }

  function changeActiveFilter(status) {
    setActiveFilter(
      includes(activeFilter, status)
        ? without(activeFilter, status)
        : concat(activeFilter, status),
    );
  }

  return (
    <div className="participants-overview">
      <div className="participants-search btn-toolbar">
        <input
          className="participants-search-input border-box p-2 w-80 text-sm"
          onChange={onSearch}
          placeholder={I18n.t("js.calendars.appointment.search_participants")}
        />
        <div className="participants-status-filter flex flex-row gap-2 items-center flex-wrap">
          <div className="flex flex-row gap-2 items-baseline flex-wrap">
            <ul className="filter-bar border-none nav nav-pills unshim">
              {map(["open", "accepted", "declined"], (status) => (
                <li
                  className={classNames("nav-item", {
                    active: includes(activeFilter, status),
                  })}
                  key={status}
                >
                  <a
                    className={classNames("filter-bar__link", {
                      active: includes(activeFilter, status),
                    })}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      changeActiveFilter(status);
                    }}
                  >
                    {I18n.t(
                      `js.calendars.appointment.participant_status.${status}`,
                    )}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <table className="w-full mt-4">
        <tbody className="divide-y divide-gray-300">
          {map(participants, (participant) => (
            <ParticipantRow
              deleteParticipant={deleteParticipant}
              changeStatus={changeStatus}
              key={participant.id}
              appointmentId={appointmentId}
              total_attendees_enforced_and_exceeded={
                total_attendees_enforced_and_exceeded
              }
              {...participant}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
ParticipantsOverview.propTypes = {
  appointmentId: PropTypes.string,
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
      membership: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        path: PropTypes.string,
        images: PropTypes.shape({
          small: PropTypes.string,
          medium: PropTypes.string,
        }),
      }),
    }),
  ),
  changeStatus: PropTypes.func,
  deleteParticipant: PropTypes.func,
  onSearch: PropTypes.func,
  changeActiveFilter: PropTypes.func,
  activeFilter: PropTypes.arrayOf(PropTypes.string),
  total_attendees_enforced_and_exceeded: PropTypes.bool,
};
