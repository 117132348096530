import { reduce, map } from "lodash";

// Stores homie to state if it is not yet known (this is a performance optimization)
const storeHomie = (state, homie) =>
  state[homie.id]
    ? state
    : {
        ...state,
        [homie.id]: homie,
      };

// Keeps track of homies, i.e. for displaying of composing status
const homies = (state = {}, action) => {
  switch (action.type) {
    case "chat/MESSAGE/RECEIVE":
    case "chat/COMPOSING/RECEIVE":
      return storeHomie(state, action.payload.from);
    case "chat/RECENT_MESSAGES/REPLY":
    case "chat/MORE_MESSAGES/REPLY":
      return reduce(map(action.payload.messages, "from"), storeHomie, state);
    default:
      return state;
  }
};

export default homies;
