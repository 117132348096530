import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { get } from "lodash";

import store from "../../../store";
import wrapProvider from "../../../helpers/components/wrapProvider";

import AppointmentPreview from "./AppointmentPreview";
import NewParticipantForm from "./NewParticipantForm";
import ParticipantsOverview from "./ParticipantsOverview";

function ParticipantsView({
  appointment,
  participants,
  changeStatus,
  deleteParticipant,
  addParticipants,
  reloadAppointment,
  isLoading,
}) {
  return isLoading ? (
    <p>{I18n.t("js.calendars.appointment.loading")}</p>
  ) : (
    <div>
      <AppointmentPreview {...appointment} />
      <NewParticipantForm
        {...appointment}
        newParticipants={addParticipants}
        reloadAppointment={reloadAppointment}
      />
      <ParticipantsOverview
        participants={participants}
        changeStatus={changeStatus}
        deleteParticipant={deleteParticipant}
        appointmentId={appointment.id}
        total_attendees_enforced_and_exceeded={get(
          appointment,
          "total_attendees_enforced_and_exceeded",
        )}
      />
    </div>
  );
}
ParticipantsView.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
      membership: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        path: PropTypes.string,
        images: PropTypes.shape({
          small: PropTypes.string,
          medium: PropTypes.string,
        }),
      }),
    }),
  ),
  changeStatus: PropTypes.func,
  deleteParticipant: PropTypes.func,
  addParticipants: PropTypes.func,
  reloadAppointment: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default compose(wrapProvider(store))(ParticipantsView);
