import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compose } from "recompose";
import { map, isEmpty, includes, get } from "lodash";

import useApiResource from "components/shared/hooks/useApiResource";
import { fetchReservableMeetingRooms } from "actions/meetingRooms";
import { reservableMeetingRoomsRequestSelector } from "selectors/meetingRooms";
import wrapProvider from "helpers/components/wrapProvider";
import store from "store";
import styles from "components/shared/styles/select.module.css";

function MeetingRoomSelect({ meeting, show, disabled, dateRange, onChange }) {
  const preSelectedRoomId = get(meeting, "room_id") || null;

  const loadData = !isEmpty(dateRange.end);

  const {
    loading,
    data: meetingRooms,
    error,
  } = useApiResource({
    actionCreator: fetchReservableMeetingRooms,
    payload: { dateRange, roomId: preSelectedRoomId },
    selector: reservableMeetingRoomsRequestSelector,
    loadData,
  });

  return show ? (
    <div className="control-group meeting-room">
      <label className="control-label">
        {I18n.t("js.calendars.appointment.meeting.meeting_rooms.label")}
      </label>
      <div className="controls custom-controls border-box">
        <div className="flex-col space-y-2 p-2">
          {disabled ? (
            <p className="text-sm">
              {I18n.t(
                "js.calendars.appointment.meeting.meeting_rooms.select_hint",
              )}
            </p>
          ) : (
            <p className="text-sm">
              {I18n.t("js.calendars.appointment.meeting.buffer_time")}
            </p>
          )}
          <div className="controls>">
            {loading ? (
              <>
                <i className="fa fa-spinner spin" />{" "}
                {I18n.t(
                  "js.calendars.appointment.meeting.meeting_rooms.loading",
                )}
              </>
            ) : error ? (
              <p className="text-danger">{I18n.t("js.generic_error")}</p>
            ) : isEmpty(meetingRooms) ? (
              <p className="text-danger">
                {I18n.t(
                  "js.calendars.appointment.meeting.meeting_rooms.nothing_free",
                )}
              </p>
            ) : (
              <Select
                isClearable
                className={styles.Selection}
                isDisabled={disabled}
                value={
                  includes(map(meetingRooms, "id"), get(meeting, "room_id"))
                    ? { value: meeting, label: meeting.room_name }
                    : null
                }
                options={map(meetingRooms, (room) => ({
                  value: room,
                  label:
                    room.name +
                    (room.occupied
                      ? " " +
                        I18n.t("js.calendars.appointment.meeting.occupied")
                      : ""),
                  isDisabled: room.occupied,
                }))}
                onChange={(e) => {
                  onChange(
                    isEmpty(e)
                      ? null
                      : { room_id: e.value.id, room_name: e.value.name },
                  );
                }}
                placeholder={I18n.t(
                  "js.calendars.appointment.meeting.meeting_rooms.select",
                )}
                classNamePrefix={"Select"}
                id="meeting-room-selection"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
MeetingRoomSelect.propTypes = {
  meeting: PropTypes.shape({
    room_id: PropTypes.string,
    reservation_id: PropTypes.string,
    room_name: PropTypes.string,
  }),
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  dateRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default compose(wrapProvider(store))(MeetingRoomSelect);
