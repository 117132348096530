import React, { useState } from "react";
import PropTypes from "prop-types";
import { map, filter, get, isEmpty } from "lodash";

import FieldError from "components/appCreator/items/form/FieldError";
import FolderSelectModal from "./FolderSelectModal";
import preventDefault from "../../helpers/components/preventDefault";
import useApiResource from "../shared/hooks/useApiResource";
import { fetchFolder } from "../../actions/files";
import { createFolderSelectorById } from "../../selectors/folders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const getIcon = (type) => {
  switch (type) {
    case "root_folder.group":
      return <FontAwesomeIcon icon={regular("users")} fixedWidth />;
    default:
      return <FontAwesomeIcon icon={regular("folder")} fixedWidth />;
  }
};

function SelectedFolder({ unselectFolder, folderId }) {
  const { data: currentFolder, loading } = useApiResource({
    actionCreator: fetchFolder,
    payload: { id: folderId, namespace: "folders" },
    selector: createFolderSelectorById(folderId),
  });

  if (isEmpty(currentFolder) || loading) {
    return (
      <div>
        <FontAwesomeIcon icon={regular("spinner")} spin />
      </div>
    );
  }

  return (
    <div className={"flex gap-2 py-2"}>
      <div>{getIcon(currentFolder.i18n_type)}</div>
      <div className="grow flex gap-2 items-center">
        {map(get(currentFolder, ["folder_path"], []), (folder) => (
          <React.Fragment key={folder.id}>
            <span className="text-muted">{folder.name}</span>
            <FontAwesomeIcon
              icon={regular("chevron-right")}
              className={"text-muted fa-sm"}
            />
          </React.Fragment>
        ))}
        {currentFolder ? <span className="">{currentFolder.name}</span> : null}
      </div>
      <div className="">
        {unselectFolder ? (
          <a
            href="#remove"
            className="fa fa-times remove btn-mini"
            onClick={preventDefault(() => unselectFolder({ id: folderId }))}
            title={I18n.t("js.remove")}
          />
        ) : null}
      </div>
    </div>
  );
}

SelectedFolder.propTypes = {
  folderId: PropTypes.string,
  unselectFolder: PropTypes.func,
};

function FolderSelectionField({ input, meta, defaultFolder }) {
  const [showModal, setShowModal] = useState(false);

  const unselectFolder = (targetFolder) => {
    input.onChange(filter(input.value, (id) => id !== targetFolder.id));
  };

  const selectFolder = (targetFolder) => {
    if (input.value.indexOf(targetFolder.id) !== -1) return;

    input.onChange([...input.value, targetFolder.id]);
  };

  return (
    <>
      <div className={"FolderSelectionField"}>
        {showModal ? (
          <FolderSelectModal
            modalTitle={I18n.t("js.files.folder_selection.modal_title")}
            currentRootFolder={defaultFolder}
            selectedItemIds={input.value}
            onSelectFolder={selectFolder}
            closeModal={() => setShowModal(false)}
          />
        ) : null}
        <div
          className={"border-box px-3 divide-dashed divide-neutral divide-y"}
        >
          {map(input.value, (folderId) => (
            <SelectedFolder
              key={folderId}
              folderId={folderId}
              unselectFolder={
                folderId === defaultFolder?.id ? null : unselectFolder
              }
            />
          ))}
          <div className={"py-2"}>
            <a
              href="#"
              onClick={preventDefault(() => setShowModal(!showModal))}
            >
              <FontAwesomeIcon
                icon={regular("folder-open")}
                fixedWidth
                className={"mr-2"}
              />
              {I18n.t("js.files.folder_selection.button")}
            </a>
          </div>
        </div>
      </div>
      {meta.error && <FieldError error={meta.error} />}
      <span className="help-block">
        {I18n.t("js.files.file.folder_selection.hint")}
      </span>
    </>
  );
}

FolderSelectionField.propTypes = {
  ...FieldError.propTypes,
  defaultFolder: PropTypes.object,
};

export default FolderSelectionField;
