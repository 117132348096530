import "./bootstrap.scss";

import "bootstrap/js/dist/dropdown";
import Modal from "bootstrap/js/dist/modal";
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/tab";
import Tooltip from "bootstrap/js/dist/tooltip";

window.bootstrap = { Modal, Tooltip };
