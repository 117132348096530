import MarkdownEditor from "./markdownEditor";
import MeetingRoomSelect from "./MeetinRoomSelect";
import Exporter, {
  AppItemsExporter,
  ConsumersExporter,
  MembershipsExporter,
} from "./SimpleExporter";

export default {
  "shared/MarkdownEditor": MarkdownEditor,
  "shared/Exporter": Exporter,
  "shared/AppItemsExporter": AppItemsExporter,
  "shared/MembershipsExporter": MembershipsExporter,
  "shared/ConsumersExporter": ConsumersExporter,
  "shared/MeetingRoomSelect": MeetingRoomSelect,
};
