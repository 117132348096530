import { useQuery, useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

export function fetchNetworkSettings() {
  return fetchApi("/administration/settings");
}
export function useNetworkSettings() {
  return useQuery("networkSettings", fetchNetworkSettings);
}

export async function updateNetworkSettings(body) {
  return fetchApi(`/administration/settings`, { method: "PUT", body });
}

export function useUpdateNetworkSettings(options) {
  return useMutation(updateNetworkSettings, options);
}
