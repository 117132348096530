import { convertRoutes } from "components/tixxt/BackboneFeature";
import { RouteObject } from "helpers/tixxt-router";

const appointmentsRoutes: RouteObject[] = convertRoutes({
  feature: "Calendars",
  featureRoutes: {
    "calendars/my": "showMyCalendars",
    "calendars/shared": "showSharedCalendars",
    "calendars/manage": "manageCalendars",
    "calendars/subscribe": "subscribeCalendars",
    "(groups/:group_slug/)calendars": "showContextCalendars",
    "calendars/new": "newCalendar",
    "calendars/:id/edit": "editCalendar",
    appointments: "showAllAppointments",
    "appointments/invites": "showAppointmentInvites",
    "appointments/invites/archive": "showAppointmentInvitesArchive",
    "appointments/participating": "showParticipatingAppointments",
    "appointments/new": "newAppointment",
    "appointments/:id": "showAppointment",
    "appointments/:id/edit": "editAppointment",
    "appointments/:id/participants": "appointmentParticipants",
    "appointments/:id/contact": "appointmentContact",
  },
});

export default appointmentsRoutes;
