import React from "react";
import { isEmpty, map } from "lodash";

import { useNetworkSettings, useUpdateNetworkSettings } from "./api";
import PageTitle from "../../layout/PageTitle";
import NetworkSettingsForm from "./NetworkSettingsForm";

function NetworkSettings() {
  const { isLoading, data: networkSettings } = useNetworkSettings();
  const { mutateAsync: updateNetworkSettings } = useUpdateNetworkSettings({
    onSuccess: () => {
      toastr.success(I18n.t("js.saving_successful"));
      window.location.reload(); // hard reload, to apply language settings
    },
  });

  const onSubmit = async (values) => {
    const body = {
      ...values,
      // The SelectField resolve to an empty array if you clear the input
      category: isEmpty(values.category) ? null : values.category,
      detection_profile_field: isEmpty(values.detection_profile_field)
        ? null
        : values.detection_profile_field,
      // Send array of group_ids instead of an array with group objects
      group_promotion_config: {
        ...values.group_promotion_config,
        group_ids: map(values.group_promotion_config.groups, (g) => g.id),
      },
    };

    return updateNetworkSettings(body);
  };

  return (
    <>
      <PageTitle
        title={I18n.t("js.administration.network_settings.edit.title")}
      />
      <div>
        <div>
          {isLoading || isEmpty(networkSettings) ? (
            <div>
              <i className="fa fa-spin fa-spinner" /> {I18n.t("js.loading")}
            </div>
          ) : (
            <NetworkSettingsForm
              initialValues={networkSettings}
              onSubmit={onSubmit}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default NetworkSettings;
