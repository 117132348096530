import { get } from "lodash";
import getFp from "lodash/fp/get";

export const getCurrentChat = getFp(["chat", "navigation", "params", "chatId"]);

export const getNetworkUrl = (state) =>
  get(state, ["chat", "session", "networkUrl"]);

export const getImageUrl = (state, imageId, size) => {
  if (!imageId) {
    return null;
  }

  const networkUrl = getNetworkUrl(state);
  if (!networkUrl) {
    return null;
  }

  return `${networkUrl}/api/storage/images/${imageId}/get/${size}`;
};

export const getProfileId = getFp(["chat", "profile", "id"]);
