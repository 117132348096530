import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { memoize } from "lodash";

import { isMuted } from "../../../selectors/chat/chat";
import {
  muteChat,
  unmuteChat,
  muteAppChat,
  unmuteAppChat,
} from "../../../actions/chat";

const MUTE_DURATIONS = memoize(() => [
  [
    I18n.t("js.chat.room.mute_hour"),
    () => moment().add(1, "hour").toDate(),
    false,
  ],
  [
    I18n.t("js.chat.room.mute_rest_of_day"),
    () => moment().endOf("day").toDate(),
    false,
  ],
  [
    I18n.t("js.chat.room.mute_rest_of_week"),
    () => moment().endOf("week").toDate(),
    false,
  ],
  [I18n.t("js.chat.room.forever"), () => moment("9999-12-31").toDate(), true],
]);

const MuteLink = connect(null, (dispatch, { chatId, muteUntil }) => ({
  onClick: (e) => {
    e.preventDefault();
    const meta = { chatId };
    let dispatchAction;

    if (muteUntil) {
      dispatchAction = window.isApp ? muteAppChat : muteChat;
    } else {
      dispatchAction = window.isApp ? unmuteAppChat : unmuteChat;
    }

    dispatch(
      dispatchAction({
        meta,
        payload: muteUntil ? { muted_until: muteUntil() } : {},
      }),
    );
  },
}))(({ label, onClick }) => (
  <a onClick={onClick} href="#">
    {label}
  </a>
));

const ChatRoomSettingsButton = ({ muted, chatId }) => (
  <div className="chatRoomSettingsButton chatHeaderActions">
    {muted ? (
      <i className="fa fa-volume-off chatMuted chatHeaderStatus" />
    ) : null}
    <div className={"dropdown"}>
      <button className={"btn btn-light btn-sm"} data-bs-toggle="dropdown">
        <i className="fa fa-ellipsis-h" />
      </button>
      <ul className="dropdown-menu">
        {muted ? (
          <li>
            <MuteLink
              label={I18n.t("js.chat.room.mute_disable")}
              muteUntil={null}
              chatId={chatId}
            />
          </li>
        ) : (
          MUTE_DURATIONS().map(([label, muteUntil, appOnly], i) =>
            appOnly && !window.isApp ? (
              <></>
            ) : (
              <li key={i}>
                <MuteLink label={label} muteUntil={muteUntil} chatId={chatId} />
              </li>
            ),
          )
        )}
      </ul>
    </div>
  </div>
);
ChatRoomSettingsButton.propTypes = {
  muted: PropTypes.bool,
};

export default connect((state, { chatId }) => ({
  muted: isMuted(state, { chatId }),
}))(ChatRoomSettingsButton);
