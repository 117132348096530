import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import {
  changeParticipantStatus,
  removeParticipation,
} from "../../../actions/participations";
import StatusButton from "./StatusButton";
import {
  showNameWithProfileField,
  ExternalMemberBadge,
} from "components/memberships/MemberNameWithAdditions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faAdjust,
  faEllipsisH,
} from "@fortawesome/pro-solid-svg-icons";
import { faCircle as faCircleRegular } from "@fortawesome/pro-regular-svg-icons";

const PARTICIPANT_STATUS_LOOKUP = {
  open: { icon: faAdjust, className: "muted" },
  accepted: { icon: faCircle, className: "text-success" },
  declined: { icon: faCircleRegular, className: "text-danger" },
};

export default function ParticipantRow({
  membership,
  status,
  total_attendees_enforced_and_exceeded,
  changeStatus,
  id,
  appointmentId,
  deleteParticipant,
}) {
  const dispatch = useDispatch();

  function onChangeParticipantStatus({ status }) {
    dispatch(
      changeParticipantStatus({
        id,
        appointmentId,
        body: { participant: { status } },
      }),
    );
    changeStatus(id, status);
  }
  function onRemoveParticipant(e) {
    e.preventDefault();
    if (
      confirm(
        I18n.t("js.calendars.appointment.remove_participant.confirm_hint"),
      )
    ) {
      dispatch(removeParticipation({ id, appointmentId }));
      deleteParticipant(id);
    }
  }

  return (
    <tr className="participant-row text-sm">
      <td className="participant-info p-2">
        <span className="flex flex-row gap-2 items-center">
          <img
            src={
              !isEmpty(membership.images)
                ? membership.images.small
                : Assets["profile_25x25.jpg"]
            }
            className="participant-membership-image rounded-full w-7 h-7"
          />
          <a href={membership.path}>
            {showNameWithProfileField({
              name: membership.name,
              detectionProfileField: membership.detection_profile_field,
            })}
          </a>
          {membership.is_external ? <ExternalMemberBadge /> : null}
        </span>
      </td>
      <td className="participant-status p-2">
        <span className="flex flex-row gap-2 items-baseline">
          <FontAwesomeIcon
            icon={PARTICIPANT_STATUS_LOOKUP[status]?.icon}
            className={`participant-status-icon ${PARTICIPANT_STATUS_LOOKUP[status]?.className}`}
          />
          {I18n.t(
            `js.calendars.appointment.participant_status.${
              status === "invited" ? "open" : status
            }`,
          )}
        </span>
      </td>
      <td className="participant-status-interactions  p-2">
        <div className="btn-group">
          <StatusButton
            changeStatus={(e) =>
              onChangeParticipantStatus({ e, status: "open" })
            }
            icon={PARTICIPANT_STATUS_LOOKUP["open"].icon}
            isActive={status === "open" || status === "invited"}
            className={PARTICIPANT_STATUS_LOOKUP["open"]?.className}
          />
          <StatusButton
            changeStatus={(e) =>
              onChangeParticipantStatus({ e, status: "accepted" })
            }
            icon={PARTICIPANT_STATUS_LOOKUP["accepted"].icon}
            isActive={status === "accepted"}
            disabled={total_attendees_enforced_and_exceeded}
            className={PARTICIPANT_STATUS_LOOKUP["accepted"]?.className}
          />
          <StatusButton
            changeStatus={(e) =>
              onChangeParticipantStatus({ e, status: "declined" })
            }
            icon={PARTICIPANT_STATUS_LOOKUP["declined"].icon}
            isActive={status === "declined"}
            className={PARTICIPANT_STATUS_LOOKUP["declined"]?.className}
          />
        </div>
      </td>
      <td className="remove-participant-button  p-2">
        <div className="btn-group pull-right">
          <a
            href="#"
            className="btn btn-light btn-sm dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            <FontAwesomeIcon icon={faEllipsisH} className="" />
          </a>
          <ul className="context-menu dropdown-menu">
            <li>
              <a href="#" onClick={onRemoveParticipant}>
                {I18n.t("js.calendars.appointment.remove_participant.title")}
              </a>
            </li>
          </ul>
        </div>
      </td>
    </tr>
  );
}
ParticipantRow.propTypes = {
  id: PropTypes.string,
  appointmentId: PropTypes.string,
  status: PropTypes.string,
  membership: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    path: PropTypes.string,
    is_external: PropTypes.bool,
    detection_profile_field: PropTypes.string,
    images: PropTypes.shape({
      small: PropTypes.string,
      medium: PropTypes.string,
    }),
  }),
  onChangeParticipantStatus: PropTypes.func,
  changeStatus: PropTypes.func,
  onRemoveParticipant: PropTypes.func,
  deleteParticipant: PropTypes.func,
  total_attendees_enforced_and_exceeded: PropTypes.bool,
};
