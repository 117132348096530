import React from "react";
import PropTypes from "prop-types";

export default function DateRange({ start, end, all_day }) {
  const format = all_day ? "LLL" : "LLLL";

  return (
    <div className="muted pt-1 text-sm leading-none flex items-center">
      <div>
        <span className="mr-1">
          {I18n.t("js.global_search.appointments.appointment_start")}{" "}
          <time>{moment(start).format(format)}</time>
        </span>
        {end
          ? [
              <br key={"br"} />,
              <span className="end" key={"end"}>
                {I18n.t("js.global_search.appointments.appointment_end")}{" "}
                <time>{moment(end).format(format)}</time>
              </span>,
            ]
          : null}
      </div>
    </div>
  );
}

DateRange.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string,
  all_day: PropTypes.bool,
};
