import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { usePrevious } from "helpers/usePrevious";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

function StatusButton({ changeStatus, icon, isActive, disabled, className }) {
  const [isLoading, setLoading] = useState(false);
  const prevLoading = usePrevious(isLoading);

  useEffect(() => {
    if (prevLoading && isActive === true) {
      setLoading(false);
    }
  });

  return (
    <button
      onClick={onChangeStatus}
      className={`btn btn-light btn-sm ${isActive ? "active" : ""}`}
      disabled={isLoading || disabled}
    >
      <FontAwesomeIcon
        icon={isLoading ? faSpinner : icon}
        className={isLoading ? "fa-spin interaction-icon-spinner" : className}
      />
    </button>
  );

  function onChangeStatus(e) {
    e.preventDefault();
    if (isActive) return;

    setLoading(true);
    changeStatus();
  }
}

StatusButton.propTypes = {
  changeStatus: PropTypes.func,
  isActive: PropTypes.bool,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
};

export default StatusButton;
