import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import React, { Fragment } from "react";
import CheckboxFields from "../../shared/fields/CheckboxFields";
import SelectionField from "../../appCreator/properties/edit/SelectionField";
import PropTypes from "prop-types";
import InfoText from "../../shared/InfoText";

const selector = formValueSelector("editMembershipSettings");
const DigestSettings = connect((state, { configCategory }) => ({
  switchValue: selector(state, `${configCategory}.switch`),
}))(({ configCategory, switchValue }) => (
  <Fragment>
    <h3>{I18n.t(`js.memberships.settings.${configCategory}.title`)}</h3>
    <p>{I18n.t(`js.memberships.settings.${configCategory}.description`)}</p>
    <CheckboxFields
      name={`${configCategory}.switch`}
      options={[
        {
          label: `js.memberships.settings.${configCategory}.checkboxes.disabled`,
          value: "disabled",
        },
        {
          label: `js.memberships.settings.${configCategory}.checkboxes.all`,
          value: "all",
        },
        {
          label: `js.memberships.settings.${configCategory}.checkboxes.selected_groups`,
          value: "selected_groups",
        },
      ]}
      multiple={false}
    />
    {switchValue === "selected_groups" ? (
      <div className="mt-3 ">
        <InfoText
          translation={
            "js.memberships.settings.email_digests.selected_groups.info"
          }
        />
        <label htmlFor={`group-select-${configCategory}-input`}>
          {I18n.t(`js.memberships.settings.${configCategory}.include_groups`)}
        </label>
        <div className="pt-3">
          <SelectionField
            select
            name={`${configCategory}.group_ids`}
            options={Tixxt.currentMember.groups().map((group) => ({
              value: group.get("id"),
              label: group.get("name"),
            }))}
            multiple
          />
        </div>
      </div>
    ) : null}
  </Fragment>
));

DigestSettings.propTypes = {
  configCategory: PropTypes.string,
};

export default DigestSettings;
