import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ChatImage from "./ChatImage";
import { getChatBusyStatus } from "../../../selectors/chat/chat";
import classNames from "classnames";

const ChatHeader = ({
  chatId,
  title,
  subtitle,
  ButtonLeft,
  ButtonRight,
  chatStatus,
}) => {
  useEffect(() => {
    if (window.isApp) {
      // hide navigation in chat
      const navigation = document.getElementById("main-navigation");
      navigation.classList = []; // remove styles otherwise hidden is overwritten
      navigation.hidden = true;
    }
  }, []);

  return (
    <div
      className={classNames(
        "px-3 flex items-center border-b border-neutral gap-3 shrink-0 h-topbar",
        {
          chat__header: !window.isApp,
          "fixed left-0 right-0 bg-white border-none z-10": window.isApp,
        },
      )}
    >
      {ButtonLeft ? ButtonLeft : null}
      {chatId ? <ChatImage chatId={chatId} name={title} /> : null}
      <div
        className="chat__title grow flex flex-col overflow-hidden"
        title={title}
      >
        <span className="font-semibold truncate text-ellipsis text-xl">
          {title}
        </span>

        {subtitle ? (
          <>
            <span className="subtitle leading-none">{subtitle}</span>
          </>
        ) : chatStatus?.text ? (
          <span
            className="subtitle leading-none truncate"
            title={chatStatus?.text}
          >
            {chatStatus?.text}
          </span>
        ) : null}
      </div>

      {ButtonRight ? ButtonRight : null}
    </div>
  );
};
ChatHeader.propTypes = {
  chatId: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  ButtonLeft: PropTypes.element,
  ButtonRight: PropTypes.element,
};

export default connect((state, { chatId }) => ({
  chatStatus: chatId ? getChatBusyStatus(state, chatId) : "",
}))(ChatHeader);
