import { useEffect, useRef } from "react";

/* One question that comes up a lot is "When using hooks how do I get the previous value of props or state?". 
 With React class components you have the componentDidUpdate method which receives previous props and state as arguments or you can update an instance variable...
 For functional components, we can create a custom hook that uses the useRef hook internally for storing the previous value. */

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export { usePrevious };
