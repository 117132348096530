import React, { useState, useEffect } from "react";
import { LinkOptions } from "../../../../../@types/appNavigation";

type Props = {
  options: LinkOptions;
  onChange: (props: { options: LinkOptions }) => void;
};

function Link({ options, onChange }: Props) {
  const [openIn, setOpenIn] = useState<"browser" | "webview">(
    options?.open_in || "webview",
  );
  const [link, setLink] = useState(options?.url || "");

  useEffect(() => {
    onChange({ options: { url: link, open_in: openIn } });
  }, [openIn, link]);

  return (
    <>
      <label>
        {I18n.t("js.administration.app_navigation_items.edit.link_url_label")}
      </label>
      <input
        className="input-text"
        value={options.url}
        onChange={(e) => setLink(e.target.value)}
      />
      <label className="checkbox">
        <input
          type="checkbox"
          name="entry_point"
          className="ml-3"
          checked={options?.open_in === "browser"}
          onChange={(e) => setOpenIn(e.target.checked ? "browser" : "webview")}
        />
        {I18n.t(
          "js.administration.app_navigation_items.edit.link_open_in_browser",
        )}
      </label>
    </>
  );
}

export default Link;
